import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      redirect: '/index'
    },
    {
      path: '/index',
      name: 'index',
      component: () => import('@/views/index.vue')
    },

    {
      path: '/about',
      name: 'about',
      component: () => import('@/views/YEAbout.vue')
    },
    {
      path: '/dynamic',
      name: 'dynamic',
      component: () => import('@/views/YEDynamic.vue')
    },
    {
      path: '/challenge',
      name: 'challenge',
      component: () => import('@/views/YEChallenge.vue')
    },
    {
      path: '/communicate',
      name: 'communicate',
      component: () => import('@/views/YECommunicate.vue')
    },
    {
      path: '/evtrain',
      name: 'evtrain',
      component: () => import('@/views/YEVtrain.vue')
    },
    {
      path: '/show',
      name: 'show',
      component: () => import('@/views/YEShow.vue')
    },
    {
      path: '/collect',
      name: 'collect',
      component: () => import('@/views/YECollect.vue')
    },
    {
      path: '/detail',
      name: 'detail',
      component: () => import('@/views/YEActivityDetail.vue')
    },
    {
      path: '/description',
      name: 'description',
      component: () => import('@/views/YEDescription.vue')
    },
    {
      path: '/videoDetail',
      name: 'videoDetail',
      component: () => import('@/views/YEVideoDetail.vue')
    }
  ],

  linkActiveClass: 'active',
  linkExactActiveClass: 'exac-active'
})
