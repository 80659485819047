import axios from 'axios'

// 实例化axios
const instance = axios.create({
  baseURL: '/api/',
  timeout: 60000,
  headers: {
    // Authorization: 'Bearer your_token',
    'Content-Type': 'application/json'
  }
})

// 配置请求拦截器
instance.interceptors.request.use(
  function (config) {
    // 这里可以修改或者添加请求头，config.headers
    // config.headers['Authorization'] =  `Bearer ${Session.get('token')}`;

    return config
  },
  function (error) {
    return Promise.reject(error)
  }
)

// 配置响应拦截器
instance.interceptors.response.use(
  function (response) {
    // 这里统一处理响应
    return response
  },
  function (error) {
    return Promise.reject(error)
  }
)

export default instance
